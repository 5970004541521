<template>
  <div
    :class="containerClasses"
    v-if="announcementUrl"
  >
    <div class="w-full">
      <BaseText
        class="title"
        position="text-center"
        size="headline-medium"
        tag="h2"
      >
        {{ $t('announcement_modal.title') }}
      </BaseText>

      <div class="flex justify-between">


        <SkeletonLoading
          v-if="loading"
          height="450px"
          width="100%"
        />

        <iframe
          v-show="!loading"
          id="announcement"
          ref="announcement"
          class="announcement-iframe"
          :src="announcementUrl"
        />
      </div>

    </div>

    <div class="action">
      <a
        :href="announcementUrl"
        target="_blank"
        rel="noopener"
      >
        <ButtonV2
          wide
          testId="btn-send"
          :label="$t('navigation.learn_more')"
        />
      </a>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import ButtonV2 from '@/stories/misc/ButtonV2';
import { BaseText } from '@/components/misc';

export default {
  name: 'AnnouncementModal',

  components: {
    BaseText,
    ButtonV2,
  },

  props: {
    announcementUrl: {
      required: true,
      type: String,
    }
  },

  data() {
    return {
      loading: true,
    };
  },

  computed: {
    containerClasses() {
      return `announcement flex flex-col justify-between items-center p-7-5 ${!this.isMobileDevice ? 'container' : 'flex-1 sm:p-4'}`;
    }
  },

  methods: {
    ...mapActions('ui', ['toggleAnnouncement']),

    toggleLoading() {
      this.loading = !this.loading;
    },

    updateAnnouncementStatus() {
      this.toggleLoading();

      this.toggleAnnouncement();
    }
  },

  mounted() {
    this.$refs.announcement.addEventListener('load', this.updateAnnouncementStatus);
  },

  beforeDestroy() {
    this.$refs.announcement.removeEventListener('load', this.toggleLoading);
  }
};
</script>

<style scoped>
.container {
  overflow: auto;
  width: 550px;
}

.title {
  @apply block pb-5;
}

.action {
  @apply flex flex-col w-full mt-n-4xl;
}

.announcement {
  min-height: 400px;
}

.announcement-iframe {
  @apply w-full max-h-full;
  margin-bottom: 40px;
  min-height: 450px;
  height: calc(100vh - 350px);
}

@media (min-width: 1200px) {
  .announcement-iframe {
    height: 450px;
  }
}

.announcement-loading {
  @apply w-full max-h-full;
  min-height: 450px;
}
</style>